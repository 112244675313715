<template>
  <v-container>
   <div
      class="d-flex"
    >
      <div :class="[`text-h4`, `mb-5`]"> {{ $route.params.id }} </div>
      <div class="ml-auto mt-2">
        <v-btn
          small
          icon
          @click="isJson = !isJson"
        >
          <v-icon>mdi-code-json</v-icon>
        </v-btn>
      </div>
    </div>
    <div>
      <fiware-registration-form v-if="!isJson && isFetch" :registration="itemsDetail" :id="$route.params.id" ref="registration"></fiware-registration-form>
      <v-jsoneditor v-if="isJson" v-model="filterRegistrationForm" :options="{ mode: 'code' }" :plus="false" height="400px" @error="jsoneditorError($event)"></v-jsoneditor>
      <confirm-dialog v-model="isCancel" type="cancle" :title="$route.params.id" @confirm="$router.go(-1)"></confirm-dialog>
    </div>
    <div
      class="d-flex mt-6"
    >
      <v-btn
        class="ml-auto mx-2"
        depressed
        small
        color="success"
        @click="$refs.registration.updateRegistration()"
      >
        <v-icon left>
          mdi-pencil
        </v-icon>
        Update
      </v-btn>
      <v-btn
        class="mx2"
        small
        depressed
        color="warning"
        @click="isCancel = true"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        Cancel
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'FiwareRegistrationDetail',
  components: {
    ConfirmDialog: () => import('@/components/ConfirmDialog'),
    FiwareRegistrationForm: () => import('@/components/fiware/FiwareRegistrationForm')
  },
  data: () => ({
    isJson: false,
    isFetch: false,
    isCancel: false,
    itemsDetail: {},
    registration: {
      id: null,
      description: null,
      provider: {
        url: null,
        supportedForwardingMode: null
      },
      dataProvided: {
        entities: [{
          key: {
            name: null,
            value: null
          },
          value: {
            name: null,
            value: null
          }
        }],
        attrs: {
          values: [],
          inputValue: null
        },
        expression: [
          {
            key: null,
            value: null
          }
        ],
        expressionz: null
      },
      status: null,
      expires: null,
      forwardingInformation: {
        timesSent: null,
        lastForwarding: null,
        lastFailure: null,
        lastSuccess: null
      }
    }
  }),
  methods: {
    fetchRegistration: function () {
      api.get(`/f/v2/registrations/${this.$route.params.id}`, this.$store.state.firewareServices, '/test').then((response) => {
        response.json().then((json) => {
          this.itemsDetail = this.convetRegistrationToForm(json)
          this.isFetch = true
        })
      })
    },
    convetRegistrationToForm: function (regist) {
      const registForm = JSON.parse(JSON.stringify(this.registration))
      if (regist.description) {
        registForm.description = regist.description
      }
      if (regist.status) {
        registForm.status = regist.status
      }
      if (regist.expires) {
        registForm.expires = regist.expires
      }
      if (regist.provider && regist.provider.http && regist.provider.http.url) {
        registForm.provider.url = regist.provider.http.url
      }
      if (regist.provider && regist.provider.supportedForwardingMode) {
        registForm.provider.supportedForwardingMode = regist.provider.supportedForwardingMode
      }
      if (regist.dataProvided && regist.dataProvided.entities) {
        let entities = []
        for (const entitie of regist.dataProvided.entities) {
          let key = {}
          let value = {}
          if (entitie.id) {
            key.name = 'id'
            key.value = entitie.id
          }
          if (entitie.idPattern) {
            key.name = 'idPattern'
            key.value = entitie.idPattern
          }
          if (entitie.type) {
            value.name = 'type'
            value.value = entitie.type
          }
          if (entitie.typePattern) {
            value.name = 'typePattern'
            value.value = entitie.typePattern
          }
          entities.push({ key: key, value: value })
        }
        registForm.dataProvided.entities = entities
        // for (const [key, value] of Object.entries(subsc.subject.condition.expression)) {}
      }
      if (regist.dataProvided && regist.dataProvided.attrs) {
        registForm.dataProvided.attrs.values = regist.dataProvided.attrs
      }
      if (regist.dataProvided && regist.dataProvided.expression) {
        registForm.dataProvided.expressionz = regist.dataProvided.expression
      }
      return registForm
    },
    convertRegistrationToJSON: function (registrationForm) {
      const registration = {
        provider: {
          http: {
            url: registrationForm.provider.url
          }
        },
        dataProvided: {
          entities: []
        }
      }
      if (registrationForm.description) {
        registration.description = registrationForm.description
      }
      if (registrationForm.status) {
        registration.status = registrationForm.status
      }
      if (registrationForm.expires) {
        registration.expires = registrationForm.expires
      }
      if (registrationForm.provider.supportedForwardingMode) {
        registration.provider.supportedForwardingMode = registrationForm.provider.supportedForwardingMode
      }
      if (registrationForm.dataProvided.entities.filter(entitie => entitie.key.value && entitie.value.value).length > 0) {
        let entities = []
        registrationForm.dataProvided.entities.filter(entitie => entitie.key.value && entitie.value.value).forEach(entitie => {
          entities.push({ [entitie.key.name]: entitie.key.value, [entitie.value.name]: entitie.value.value })
        })
        registration.dataProvided.entities = entities
      }
      if (registrationForm.dataProvided.attrs.values.length > 0) {
        registration.dataProvided.attrs = registrationForm.dataProvided.attrs.values
      }
      if (registrationForm.dataProvided.expressionz) {
        registration.dataProvided.expression = registrationForm.dataProvided.expressionz
      }
      if (registrationForm.forwardingInformation.timesSent) {
        registration.forwardingInformation.timesSent = registrationForm.forwardingInformation.timesSent
      }
      if (registrationForm.forwardingInformation.lastForwarding) {
        registration.forwardingInformation.lastForwarding = registrationForm.forwardingInformation.lastForwarding
      }
      if (registrationForm.forwardingInformation.lastFailure) {
        registration.forwardingInformation.lastFailure = registrationForm.forwardingInformation.lastFailure
      }
      if (registrationForm.forwardingInformation.lastSuccess) {
        registration.forwardingInformation.lastSuccess = registrationForm.forwardingInformation.lastSuccess
      }
      return registration
    }
  },
  computed: {
    filterRegistrationForm: {
      get () {
        return this.convertRegistrationToJSON(this.itemsDetail)
      },
      set (regist) {
        this.itemsDetail = this.convetRegistrationToForm(regist)
      }
    }
  },
  watch: {
    '$store.state.fiwareService': function () {
      this.$router.go(-1)
    }
  },
  created () {
    this.fetchRegistration()
  }
}
</script>
